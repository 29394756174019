/* eslint-disable consistent-return */
import { flow, makeAutoObservable } from 'mobx';
import { UPDATE_SETTINGS_MUTATION, Settings } from '@graphql';
import { graphqlApi } from '@utils';
import { toast } from 'react-toastify';
import { store } from '@store';
import { DEFAULT_HTTP_RESPONSE } from './defaults';

export class SettingsStore {
  settingsResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  constructor() {
    makeAutoObservable(this);
  }

  updateSettings = flow(function* updateSettings(this: SettingsStore, variables: Settings): any {
    try {
      this.settingsResponse.status = 'pending';
      const userResponse = yield graphqlApi(UPDATE_SETTINGS_MUTATION, { input: variables });
      this.settingsResponse.status = 'ok';
      if (userResponse?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      const userInfo = userResponse?.data?.data?.updateSettings;
      if (userInfo) toast('Settings updated.', { type: 'success' });
      else toast('Settings update failed.', { type: 'info' });
      return userInfo;
    } catch (err) {
      toast('Something went wrong', { type: 'success' });
      this.settingsResponse.status = 'badRequest';
      this.settingsResponse.errMessage = 'Bad Request';
    }
  }).bind(this);
}
