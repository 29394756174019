import React, { useContext } from 'react';
import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { ALL_ADVISORS_QUERY, ORGANIZATION_QUERY } from '@graphql';
import { CustomModal } from '@components';
import { appContext } from '@utils';
import { useParams } from 'react-router-dom';
import { GridRowId } from '@mui/x-data-grid';

const makeStyles = () => ({
  formItems: {
    margin: '0.5rem 0',
    display: 'flex',
  },
});

interface IProps {
  handleClose: () => void;
  clients: GridRowId[];
}

export function DeleteClient({ handleClose, clients }: IProps) {
  const sx = makeStyles();

  const { id } = useParams<any>();

  const {
    organizationStore: { deleteClientsFromOrg },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      await deleteClientsFromOrg({ orgId: id, clients: clients as string[] });
      await getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(id), ALL_ADVISORS_QUERY] });
      handleClose();
    }
  });
  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant='h6' component='h2'>
          Delete Client
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <div>Are you sure you want to delete these clients?</div>
          <div style={{
            display: 'flex',
            marginTop: '10px',
          }}>
            <Button
              style={{ marginRight: '10px' }}
              sx={sx.formItems}
              fullWidth
              variant='contained'
              disabled={formik.isSubmitting}
              type='submit'
            >
              Yes
            </Button>
            <Button
              sx={sx.formItems}
              fullWidth
              variant='contained'
              disabled={formik.isSubmitting}
              type='button'
              onClick={handleClose}
            >
              No
            </Button>
          </div>
        </form>
      </>
    </CustomModal>
  );
}
