import React, {useContext, useEffect} from 'react';
import { Grid } from '@mui/material';
import {appContext} from "@utils";
import { AllManagersTable } from './_/AllManagersTable';

const makeStyles = () => ({
  pageContainer: {
    height: '100%',
  },
});
export function ManagersPage() {
  const sx = makeStyles();
  const {
    managersStore:{fetchAllManagers,resetManagers}
  } = useContext(appContext);
  useEffect(() => {
    fetchAllManagers();
    return ()=>resetManagers()
  }, [fetchAllManagers,resetManagers]);
  return (
    <Grid sx={sx.pageContainer} container justifyContent="center" alignItems="center">
      <AllManagersTable />
    </Grid>
  );
}
