import React from 'react';
import { Modal, Box } from '@mui/material';

const makeStyles = () => ({
  modalContainer: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
});
interface IProps {
  handleClose: () => void;
  children: JSX.Element;
}
export function CustomModal({ handleClose, children }: IProps) {
  const sx = makeStyles();
  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={sx.modalContainer}>{children}</Box>
    </Modal>
  );
}
