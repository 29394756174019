/* eslint-disable react/react-in-jsx-scope */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Button } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { appContext } from '@utils';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ALL_ADVISORS_QUERY, Client, ORGANIZATION_QUERY } from '@graphql';
import { useParams } from 'react-router-dom';
import { AddClient } from '../AddClient';
import { DeleteClient } from '../DeleteClient';
import { EditClient } from '../EditClient';
import { ImportClient } from '../ImportClient';
import { InviteClient } from '../InviteClient';

function CustomToolbar({ isRowsSelected, handleInviteModal, handleDeleteModal }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [isImportClientOpen, setImportClientOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(!open);
  };
  const importClient = () => {
    setImportClientOpen(!isImportClientOpen);
  };
  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <div>
        <Button variant="contained" onClick={importClient}>
          Import Client
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            ml: '1rem',
          }}
        >
          Add Client
        </Button>
        {isRowsSelected && (
          <>
          <Button
            variant="contained"
            onClick={() => handleInviteModal()}
            sx={{
              ml: '1rem',
            }}
          >
            Invite Clients
          </Button>
          <Button
          variant="contained"
          onClick={() => handleDeleteModal()}
          sx={{
            ml: '1rem',
          }}
        >
          Delete Clients
        </Button>
          </>
          
        )}
      </div>
      {open && <AddClient handleClose={handleClose} />}
      {isImportClientOpen && <ImportClient handleClose={importClient} />}
    </GridToolbarContainer>
  );
}
export const OrganizationDetailTable = observer(() => {
  // const [openAdvisorModal, setOpenAdvisorModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [client, setClient] = useState<Client | GridRowId[] | null>(null);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  // const handleAdvisorModal = useCallback(() => {
  //   setOpenAdvisorModal(!openAdvisorModal);
  // }, [openAdvisorModal]);

  const handleDeleteModal = useCallback(() => {
    setOpenDeleteModal(!openDeleteModal);
  }, [openDeleteModal]);

  const handleInviteModal = useCallback(() => {
    setOpenInviteModal(!openInviteModal);
  }, [openInviteModal]);

  const handleEditModal = useCallback(() => {
    setOpenEditModal(!openEditModal);
  }, [openEditModal]);

  const {
    userStore: { allClients, getCurrentSession, sessionResponse, user },
  } = useContext(appContext);
  const { id } = useParams<any>();

  useEffect(() => {
    getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(id), ALL_ADVISORS_QUERY] });
  }, [getCurrentSession, id]);

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 1 },
      { field: 'email', headerName: 'Email', disableColumnMenu: true, flex: 1 },
      { field: 'workEmail', headerName: 'Work Email', disableColumnMenu: true, flex: 1 },
      { field: 'firstName', headerName: 'First Name', disableColumnMenu: true, flex: 1 },
      { field: 'lastName', headerName: 'Last Name', disableColumnMenu: true, flex: 1 },
      { field: 'phone', headerName: 'Phone', disableColumnMenu: true, flex: 1 },
      { field: 'role', headerName: 'Role', disableColumnMenu: true, flex: 1 },
      {
        field: 'invitationStatus',
        headerName: 'Invitation Status',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'onboardingStatus',
        headerName: 'Onboarding Status',
        disableColumnMenu: true,
        flex: 1,
      },
      // {
      //   field: 'advisor',
      //   headerName: 'Advisor',
      //   disableColumnMenu: true,
      //   flex: 1,
      //   valueGetter: (params: any) => {
      //     if (params.row.advisor)
      //       return `${params.row.advisor.firstName} ${params.row.advisor.lastName}`;
      //     return null;
      //   },
      // },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          // <GridActionsCellItem
          //   icon={<SupervisorAccountIcon />}
          //   label={params.row.advisor ? 'Change Advisor' : 'Assign Advisor'}
          //   onClick={() => {
          //     setClient(params.row);
          //     handleAdvisorModal();
          //   }}
          //   showInMenu
          // />,
          <GridActionsCellItem
            icon={<SupervisorAccountIcon />}
            label={params.row.invited ? 'Invite Again' : 'Send Invite'}
            disabled={rowSelectionModel.length > 0}
            onClick={() => {
              setClient([params.row.id]);
              handleInviteModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Client"
            onClick={() => {
              setClient([params.row.id]);
              handleDeleteModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Client"
            onClick={() => {
              setClient(params.row);
              handleEditModal();
            }}
            showInMenu
          />,
        ],
      },
    ],
    [
      rowSelectionModel.length,
      // handleAdvisorModal,
      handleInviteModal,
      handleDeleteModal,
      handleEditModal,
    ],
  );
  return (
    <div style={{ height: 'inherit', width: '100%' }}>
      <h2 style={{ color: 'rgb(224, 224, 224)' }}>{user.organization?.name}</h2>
      <DataGrid
        rows={allClients as any}
        columns={columns}
        checkboxSelection
        onSelectionModelChange={(items) => setRowSelectionModel(items)}
        componentsProps={{
          toolbar: { isRowsSelected: rowSelectionModel.length > 0, handleInviteModal, handleDeleteModal },
        }}
        loading={sessionResponse.status === 'pending'}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      {/* {openAdvisorModal && (
        <AssignAdvisor handleClose={handleAdvisorModal} client={client as any} />
      )} */}
      {openDeleteModal && <DeleteClient handleClose={handleDeleteModal} clients={rowSelectionModel.length ? rowSelectionModel : (client as GridRowId[])} />}
      {openInviteModal && (
        <InviteClient
          handleClose={handleInviteModal}
          clients={rowSelectionModel.length ? rowSelectionModel : (client as GridRowId[])}
        />
      )}
      {openEditModal && <EditClient handleClose={handleEditModal} client={client as any} />}
    </div>
  );
});
