import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { Switch, Router, Route } from 'react-router-dom';
import { Container, useTheme, Theme } from '@mui/material';
import { Routes, appContext } from '@utils';
import { AuthLayout } from '@components';

const makeStyles = (theme: Theme) => ({
  appContainer: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '100%',
    },
    padding: '0 !important',
    backgroundColor: theme.palette.background.default,
  },
});
function App() {
  const theme = useTheme();
  const sx = makeStyles(theme);
  const store = useContext(appContext);
  const [initialSession, setInitialSession] = useState<boolean>(false);
  const {
    historyStore,
    userStore: { getCurrentSession },
  } = store;

  useEffect(() => {
    const checkInitialSession = async () => {
      await getCurrentSession();
      setInitialSession(true);
    };
    checkInitialSession();
  }, [getCurrentSession]);
  return (
    <Container maxWidth="xl" sx={sx.appContainer}>
      <Router history={historyStore.history}>
        {initialSession && (
          <Switch>
            {Routes('unAuth')}
            <Route path="/" component={AuthLayout} />
          </Switch>
        )}
      </Router>
    </Container>
  );
}

export default App;
