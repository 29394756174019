import { CustomModal } from '@components';
import { Typography, Button } from '@mui/material';
import { appContext } from '@utils';
import { useFormik } from 'formik';
import React, { useContext } from 'react';

const makeStyles = () => ({
  formItems: {
    margin: '0.5rem 0',
    display: 'flex',
  },
});

interface IProps {
  handleClose: () => void;
  managerId: string;
}

export function DeleteManager({ handleClose, managerId }: IProps) {
  const sx = makeStyles();

  const mid = managerId;

  const {
    managersStore: { deleteManager, fetchAllManagers },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: { mid },
    onSubmit: async () =>
      (await deleteManager({ id: mid })) && (await fetchAllManagers()) && handleClose(),
  });
  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Delete Admin
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <div>Are you sure you want to delete this admin?</div>
          <div
            style={{
              display: 'flex',
              marginTop: '10px',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              sx={sx.formItems}
              fullWidth
              variant="contained"
              disabled={formik.isSubmitting}
              type="submit"
            >
              Yes
            </Button>
            <Button
              sx={sx.formItems}
              fullWidth
              variant="contained"
              disabled={formik.isSubmitting}
              type="button"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      </>
    </CustomModal>
  );
}

export default DeleteManager;
