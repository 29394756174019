import React from 'react';
import { Grid } from '@mui/material';
import { AllAdvisorsTable } from './_/AllAdvisorsTable';

const makeStyles = () => ({
  pageContainer: {
    height: '100%',
  },
});
export function AdvisorsPage() {
  const sx = makeStyles();
  return (
    <Grid sx={sx.pageContainer} container justifyContent="center" alignItems="center">
      <AllAdvisorsTable />
    </Grid>
  );
}
