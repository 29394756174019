/* eslint-disable react/react-in-jsx-scope */
import { CustomModal } from '@components';
import {
  ALL_ADVISORS_QUERY,
  ALL_ORGANIZATIONS_QUERY,
  Advisor,
  ClientOrganization,
  OrganizationStatus,
  UpdateClientOrganizationInput,
} from '@graphql';
import {
  Button,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { appContext, sortByProperty } from '@utils';
import { useFormik } from 'formik';
import { useContext, useEffect, useMemo } from 'react';

import WorkIcon from '@mui/icons-material/Work';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
  disabledMenuItem: {
    pointerEvents: 'auto !important',
    cursor: 'not-allowed !important',
  },
  fullsizeSpan: { width: '100%' },
});

interface IProps {
  handleClose: () => void;
  org: ClientOrganization;
  advisors: Advisor[];
}

export function EditOrganization({ handleClose, org, advisors }: IProps) {
  const sx = makeStyles();

  const initialValues = useMemo<UpdateClientOrganizationInput>(
    () => ({
      id: org.id,
      name: org.name,
      status: org.status,
      advisorId: org.advisor.id || ' ',
    }),
    [org],
  );

  const {
    organizationStore: { updateOrganization },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) =>
      (await updateOrganization(values)) &&
      (await getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY] })) &&
      handleClose(),
  });

  useEffect(() => {
    getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] });
  }, [getCurrentSession]);

  // Sort the advisors by whether they have a booking URL
  const sortedAdvisors = useMemo(() => {
    if (!advisors) return [];
    return sortByProperty(advisors, 'bookingUrl') as Advisor[];
  }, [advisors]);

  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Edit Organization
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Org Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorkIcon />
                </InputAdornment>
              ),
            }}
          />

          <Select
            sx={sx.formItems}
            fullWidth
            name="status"
            onChange={formik.handleChange}
            value={formik.values.status}
          >
            <MenuItem value={' '} disabled>
              Select Status
            </MenuItem>
            <MenuItem value={OrganizationStatus.Acitve}>Active</MenuItem>
            <MenuItem value={OrganizationStatus.Inactive}>Inactive</MenuItem>
          </Select>

          <Select
            sx={sx.formItems}
            fullWidth
            name="advisorId"
            onChange={(e) => {
              if (!advisors.find((advisor) => advisor.id === e.target.value)?.bookingUrl) {
                // Do not set the value if the advisor does not have a booking URL
                return;
              }

              formik.setFieldValue('advisorId', e.target.value);
            }}
            value={formik.values.advisorId}
          >
            <MenuItem value={' '} disabled>
              Assign an Advisor
            </MenuItem>

            {sortedAdvisors?.map((advisor) => {
              // Determine if the advisor is disabled
              const isDisabled = !advisor.bookingUrl;

              // Tooltip title
              const tooltipTitle = isDisabled
                ? "This advisor cannot be assigned because they don't have a booking URL."
                : '';

              return (
                <MenuItem
                  sx={isDisabled ? sx.disabledMenuItem : {}}
                  value={advisor.id}
                  disabled={isDisabled}
                >
                  <Tooltip key={advisor.id} title={tooltipTitle}>
                    <span
                      style={sx.fullsizeSpan}
                    >{`${advisor.firstName} ${advisor.lastName}`}</span>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </Select>

          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            Save
          </Button>
        </form>
      </>
    </CustomModal>
  );
}
