import { ThemeOptions } from '@mui/material';

export const dark: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      paper: '#062121',
    },
  },
};
export const light: ThemeOptions = {
  palette: {
    background: {
      default: '#D9E4DD',
    },
  },
};
