import React, { useContext, useMemo } from 'react';
import { Button, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { SuperAdminRole, UpdateManagerInput } from '@graphql';
import { CustomModal } from '@components';
import { appContext } from '@utils';

import WorkIcon from '@mui/icons-material/Work';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
});

interface IProps {
  handleClose: () => void;
  admin: any;
}

export function UpdateAdmin({ handleClose, admin }: IProps) {
  const sx = makeStyles();

  const initialValues = useMemo<UpdateManagerInput>(
    () => ({
      id: admin.id,
      name: admin.name,
      role: admin.role,
    }),
    [admin],
  );

  const {
    managersStore: { updateManager, fetchAllManagers },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) =>
      (await updateManager(values)) && (await fetchAllManagers()) && handleClose(),
  });

  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Edit Admin
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Admin Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorkIcon />
                </InputAdornment>
              ),
            }}
          />

          <Select
            sx={sx.formItems}
            fullWidth
            name="role"
            onChange={formik.handleChange}
            value={formik.values.role}
          >
            <MenuItem value={' '} disabled>
              Select Role
            </MenuItem>
            <MenuItem value={SuperAdminRole.Admin}>Admin</MenuItem>
            <MenuItem value={SuperAdminRole.Manager}>Manager</MenuItem>
          </Select>

          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            Save
          </Button>
        </form>
      </>
    </CustomModal>
  );
}
