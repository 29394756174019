import { Button, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { appContext } from '@utils';
import { CustomModal } from '@components';
import { ALL_ADVISORS_QUERY } from '@graphql';
import { useFormik } from 'formik';

interface IProps {
  handleClose: () => void;
  advisor: any;
}

const makeStyles = () => ({
  formItems: {
    margin: '0.5rem 0',
    display: 'flex',
  },
});

export function DeleteAdvisors({ handleClose, advisor }: IProps) {
  const sx = makeStyles();

  const {
    advisorStore: { deleteAdvisor },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const advisorId = useMemo(() => advisor.id, [advisor]);

  const formik = useFormik({
    initialValues: { advisorId },
    onSubmit: async () =>
      (await deleteAdvisor({ id: advisorId })) &&
      (await getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] })) &&
      handleClose(),
  });

  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Delete Advisor
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <div>Are you sure you want to delete this advisor?</div>
          <div
            style={{
              display: 'flex',
              marginTop: '10px',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              sx={sx.formItems}
              fullWidth
              variant="contained"
              disabled={formik.isSubmitting}
              type="submit"
            >
              Yes
            </Button>
            <Button
              sx={sx.formItems}
              fullWidth
              variant="contained"
              disabled={formik.isSubmitting}
              type="button"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      </>
    </CustomModal>
  );
}
