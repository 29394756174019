import React from 'react';
import { Grid } from '@mui/material';
import { AllOrganizationsTable } from './_';

const makeStyles = () => ({
  pageContainer: {
    height: '100%',
  },
});
export function OrganizationsPage() {
  const sx = makeStyles();
  return (
    <Grid sx={sx.pageContainer} container justifyContent="center" alignItems="center">
      <AllOrganizationsTable />
    </Grid>
  );
}
