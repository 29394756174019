/* eslint-disable consistent-return */
import { flow, makeAutoObservable } from 'mobx';
import {
  ALL_ADMINS_QUERY,
  ASSIGN_MANAGER_MUTATION,
  AssignManagerInput,
  CREATE_MANAGER_MUTATION,
  DELETE_ADMIN_MUTATION,
  ManagerInput,
  SuperAdmin,
  UPDATE_ADMIN_MUTATION,
  UpdateManagerInput,
} from '@graphql';
import { graphqlApi } from '@utils';
import { toast } from 'react-toastify';
import { store } from '@store';
import { DEFAULT_HTTP_RESPONSE } from './defaults';

export class ManagersStore {
  managers: Array<SuperAdmin> = [];

  createManagerResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  assignManagerResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  allManagersResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  deletaManagerResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  updateManagerResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  constructor() {
    makeAutoObservable(this);
  }

  resetManagers = () => {
    this.managers = [];
  };

  createManager = flow(function* createManager(this: ManagersStore, variables: ManagerInput): any {
    try {
      this.createManagerResponse.status = 'pending';
      const userResponse = yield graphqlApi(CREATE_MANAGER_MUTATION, variables);
      this.createManagerResponse.status = 'ok';
      if (userResponse?.data?.errors) {
        const { message } = userResponse?.data?.errors[0] || {};
        if (message === 'Unauthorized') store.userStore.clearSession();
        else if (message === 'Forbidden resource') toast('Access denied', { type: 'error' });
        else toast('Account already exist.', { type: 'info' });
      }
      const userInfo = userResponse?.data?.data?.createManager;
      if (userInfo) {
        toast('Manager created.', { type: 'success' });
        this.managers = [...this.managers, userInfo];
      }
      return userInfo;
    } catch (err) {
      toast('Something went wrong', { type: 'success' });
      this.createManagerResponse.status = 'badRequest';
      this.createManagerResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  fetchAllManagers = flow(function* allManagers(this: ManagersStore): any {
    try {
      this.allManagersResponse.status = 'pending';
      const userResponse = yield graphqlApi(ALL_ADMINS_QUERY);
      this.allManagersResponse.status = 'ok';
      if (userResponse?.data?.errors) {
        const { message } = userResponse?.data?.errors[0] || {};
        if (message === 'Unauthorized') store.userStore.clearSession();
      }
      const userInfo = userResponse?.data?.data?.allAdmins ?? [];
      this.managers = userInfo;
      return userInfo;
    } catch (err) {
      toast('Something went wrong', { type: 'success' });
      this.allManagersResponse.status = 'badRequest';
      this.allManagersResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  assignManager = flow(function* assignManager(
    this: ManagersStore,
    variables: AssignManagerInput,
  ): any {
    try {
      this.assignManagerResponse.status = 'pending';
      const userResponse = yield graphqlApi(ASSIGN_MANAGER_MUTATION, variables);
      this.assignManagerResponse.status = 'ok';
      if (userResponse?.data?.errors) {
        const { message } = userResponse?.data?.errors[0] || {};
        if (message === 'Unauthorized') store.userStore.clearSession();
        else if (message === 'Forbidden resource') toast('Access denied', { type: 'error' });
        else toast(message, { type: 'error' });
      }
      const userInfo = userResponse?.data?.data?.assignManager;
      if (userInfo) {
        toast('Manager Assigned.', { type: 'success' });
      }
      return userInfo;
    } catch (err) {
      toast('Something went wrong', { type: 'success' });
      this.assignManagerResponse.status = 'badRequest';
      this.assignManagerResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  deleteManager = flow(function* deleteManager(
    this: ManagersStore,
    variables: { id: string },
  ): any {
    try {
      this.deletaManagerResponse.status = 'pending';
      const response = yield graphqlApi(DELETE_ADMIN_MUTATION, variables);
      this.deletaManagerResponse.status = 'ok';
      if (response?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      toast('Admin deleted', { type: 'success' });
      return !!response.data.data.deleteSuperAdmin;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
      this.deletaManagerResponse.status = 'badRequest';
      this.deletaManagerResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  updateManager = flow(function* updateManager(
    this: ManagersStore,
    variables: UpdateManagerInput,
  ): any {
    try {
      this.updateManagerResponse.status = 'pending';
      const response = yield graphqlApi(UPDATE_ADMIN_MUTATION, variables);
      this.updateManagerResponse.status = 'ok';
      if (response?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      toast('Admin updated', { type: 'success' });
      return !!response.data.data.updateAdmin;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
      this.updateManagerResponse.status = 'badRequest';
      this.updateManagerResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

}
