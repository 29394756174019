/* eslint-disable react/react-in-jsx-scope */
import { CustomModal } from '@components';
import {
  Advisor,
  ALL_ADVISORS_QUERY,
  ALL_ORGANIZATIONS_QUERY,
  ClientOrganizationInput,
  SuperAdminRole,
} from '@graphql';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import {
  Button,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { appContext, sortByProperty } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import { useContext, useEffect, useMemo } from 'react';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
  disabledMenuItem: {
    pointerEvents: 'auto !important',
    cursor: 'not-allowed !important',
  },
  fullsizeSpan: { width: '100%' }
});
interface IProps {
  handleClose: () => void;
}
export const CreateOrganization = observer(({ handleClose }: IProps) => {
  const sx = makeStyles();
  const initialValues = useMemo<ClientOrganizationInput>(
    () => ({ email: '', lastName: '', firstName: '', name: '', managerId: ' ', advisorId: ' ' }),
    [],
  );

  const {
    organizationStore: { createClientOrg },
    managersStore: { managers, fetchAllManagers, resetManagers },
    userStore: { getCurrentSession, user },
  } = useContext(appContext);

  useEffect(() => {
    getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] });
  }, [getCurrentSession]);

  useEffect(() => {
    fetchAllManagers();
    return () => resetManagers();
  }, [fetchAllManagers, resetManagers]);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) =>
      (await createClientOrg(values)) &&
      (await getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY] })) &&
      handleClose(),
  });

  const customHandleFormikChange = (e: SelectChangeEvent<string | null>) => {
    if (e.target.name === 'advisorId') {
      if (user.allAdvisors?.find((advisor) => advisor.id === e.target.value)?.bookingUrl) {
        formik.setFieldValue('advisorId', e.target.value);
      }
    }
  };

  // Sort the advisors by whether they have a booking URL
  const sortedAdvisors = useMemo(() => {
    if (!user.allAdvisors) return [];
    return sortByProperty(user.allAdvisors, 'bookingUrl') as Advisor[];
  }, [user.allAdvisors]);

  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Create Organization
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Org Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorkIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Org Admin Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            type="email"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Org Admin First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Org Admin Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          {user.adminRole === SuperAdminRole.Admin && (
            <Select
              sx={sx.formItems}
              fullWidth
              name="managerId"
              required
              onChange={formik.handleChange}
              value={formik.values.managerId}
            >
              <MenuItem value={' '} disabled>
                Assign an Account Manager
              </MenuItem>
              {managers.map((manager) => (
                <MenuItem value={manager.id}>{`${manager.name}`}</MenuItem>
              ))}
            </Select>
          )}

          {user.adminRole === SuperAdminRole.Admin && (
            <Select
              sx={sx.formItems}
              fullWidth
              name="advisorId"
              onChange={(e) => customHandleFormikChange(e)}
              value={formik.values.advisorId}
            >
              <MenuItem value={' '} disabled>
                Assign an Advisor
              </MenuItem>
              {sortedAdvisors?.map((advisor) => {
                // Determine if the advisor is disabled
                const isDisabled = !advisor.bookingUrl;

                // Tooltip title
                const tooltipTitle = isDisabled
                  ? "This advisor cannot be assigned because they don't have a booking URL."
                  : '';

                return (
                  <MenuItem
                    sx={isDisabled ? sx.disabledMenuItem : {}}
                    value={advisor.id}
                    disabled={isDisabled}
                  >
                    <Tooltip key={advisor.id} title={tooltipTitle}>
                      <span style={sx.fullsizeSpan}>{`${advisor.firstName} ${advisor.lastName}`}</span>
                    </Tooltip>
                  </MenuItem>
                );
              })}
            </Select>
          )}

          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            Save
          </Button>
        </form>
      </>
    </CustomModal>
  );
});
