import React, { useContext, useEffect, useMemo } from 'react';
import { Button, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import {
  ALL_ORGANIZATIONS_QUERY,
  AssignManagerInput,
  ClientOrganization,
} from '@graphql';
import { CustomModal } from '@components';
import { appContext } from '@utils';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { observer } from 'mobx-react';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
});
interface IProps {
  handleClose: () => void;
  org: ClientOrganization;
}
export const AssignManager = observer(({ handleClose, org }: IProps) => {
  const sx = makeStyles();
  const initialValues = useMemo<AssignManagerInput>(
    () => ({ managerId: org.admin.id, name: org.name, orgId: org.id }),
    [org],
  );

  const {
    userStore: { getCurrentSession },
    managersStore: { managers, fetchAllManagers, resetManagers, assignManager },
  } = useContext(appContext);

  useEffect(() => {
    fetchAllManagers();
    return () => resetManagers();
  }, [fetchAllManagers, resetManagers]);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) =>
      (await assignManager(values)) &&
      (await getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY] })) &&
      handleClose(),
  });
  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Assign Manager
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Org Name"
            name="name"
            disabled
            value={formik.values.name}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <Select
            sx={sx.formItems}
            fullWidth
            name="managerId"
            required
            onChange={formik.handleChange}
            value={formik.values.managerId}
          >
            <MenuItem value={' '} disabled>
              Assign an Account Manager
            </MenuItem>
            {managers.map((manager) => (
              <MenuItem value={manager.id}>{`${manager.name}`}</MenuItem>
            ))}
          </Select>

          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            ASSIGN
          </Button>
        </form>
      </>
    </CustomModal>
  );
});
