import {UserStore, AdvisorsStore, OrganizationsStore, SettingsStore, ManagersStore} from './pages';
import { HistoryStore } from './data';

export const store = {
  historyStore: new HistoryStore(),
  userStore: new UserStore(),
  advisorStore: new AdvisorsStore(),
  organizationStore: new OrganizationsStore(),
  settingsStore: new SettingsStore(),
  managersStore:new ManagersStore()
};
export type IStore = typeof store;
