import React, { useContext, useMemo } from 'react';
import { Button, Typography, TextField, InputAdornment } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useFormik } from 'formik';
import {ManagerInput} from '@graphql';
import { CustomModal } from '@components';
import EmailIcon from '@mui/icons-material/Email';
import { appContext } from '@utils';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
});
interface IProps {
  handleClose: () => void;
}
export function CreateManagers({ handleClose }: IProps) {
  const sx = makeStyles();
  const {
    managersStore: {createManager},
    userStore: { getCurrentSession },
  } = useContext(appContext);
  const initialValues = useMemo<ManagerInput>(
    () => ({ email: '', name: '' }),
    [],
  );
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) =>
      (await createManager(values)) &&
      (await getCurrentSession()) &&
      handleClose(),
  });
  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Create Manager
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            type="email"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            Save
          </Button>
        </form>
      </>
    </CustomModal>
  );
}
