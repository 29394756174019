import React, {FC, useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { appContext, paths } from '@utils';
import { observer } from 'mobx-react';

interface IProps {
  Component: FC<any>;
  path: string;
  exact?: boolean;
}

export const AuthRoute = observer((props: IProps) => {
  const { Component, ...rest } = props;
  const { userStore: { isAuth } } = useContext(appContext);

  return <Route {...rest}
                render={(matchProps:any) =>
                (isAuth ? <Component {...matchProps} /> : <Redirect to={paths.login} />)} />;
});
