/**
 * Sorts an array of objects by a property.
 *
 * @param   arr       An array of objects.
 * @param   property  The property to sort by.
 *
 * @returns           The sorted array.
 */
export const sortByProperty = (arr: any[], property: string): any[] => {
  const sortedArr = arr
    ?.slice()
    .sort((a, b) => Number(b[property] !== undefined) - Number(a[property] !== undefined))
    .reverse();

  return sortedArr;
};
