const API_HOSTS = {
  dev: 'http://localhost:3000',
  stage: 'https://api-stage.elektrafi.io',
  prod: 'https://api.elektrafi.io',
};

const env = process.env.REACT_APP_HOST_ENV;
const host = API_HOSTS[env as keyof typeof API_HOSTS] || API_HOSTS.dev;

export const apiBaseUrl = `${host}/graphql`;
