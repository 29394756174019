import * as React from 'react';
import {
  DataGrid,
  // GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { appContext } from '@utils';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { SuperAdmin } from '@graphql';
import { CreateManagers } from '../CreateManager';
import { DeleteManager } from '../DeleteManager';
import { UpdateAdmin } from '../UpdateAdmin';

function CustomToolbar() {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <Button variant="contained" onClick={handleClose}>
        Create Manager
      </Button>
      {open && <CreateManagers handleClose={handleClose} />}
    </GridToolbarContainer>
  );
}
export const AllManagersTable = observer(() => {
  const [manager, setManager] = useState<SuperAdmin | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const handleDeleteModal = useCallback(() => {
    setOpenDeleteModal(!openDeleteModal);
  }, [openDeleteModal]);

  const handleUpdateModal = useCallback(() => {
    setOpenUpdateModal(!openUpdateModal);
  }, [openUpdateModal]);

  const {
    managersStore: { managers, allManagersResponse },
  } = useContext(appContext);

  const columns = React.useMemo<GridColumns>(
    () => [
      { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 1 },
      { field: 'email', headerName: 'Email', disableColumnMenu: true, flex: 1 },
      { field: 'name', headerName: 'Name', disableColumnMenu: true, flex: 1 },
      { field: 'role', headerName: 'Role', disableColumnMenu: true, flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Admin"
            onClick={() => {
              setManager(params.row);
              handleUpdateModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Admin"
            onClick={() => {
              setManager(params.row);
              handleDeleteModal();
            }}
            showInMenu
          />,
        ],
      },
    ],
    [handleDeleteModal, handleUpdateModal],
  );

  return (
    <div style={{ height: 'inherit', width: '100%' }}>
      <DataGrid
        rows={managers}
        columns={columns}
        isRowSelectable={() => false}
        loading={allManagersResponse.status === 'pending'}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      {openUpdateModal && <UpdateAdmin handleClose={handleUpdateModal} admin={manager as any} />}
      {openDeleteModal && (
        <DeleteManager handleClose={handleDeleteModal} managerId={manager?.id as string} />
      )}
    </div>
  );
});
