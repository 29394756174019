import React, { FC, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { appContext } from '@utils';
import { observer } from 'mobx-react';

interface IProps {
  Component: FC<any>;
  path: string;
  exact?: boolean;
}

export const UnAuthRoute = observer((props: IProps) => {
  const { Component, ...rest } = props;
  const store = useContext(appContext);
  const {
    userStore: { isAuth },
  } = store;
  return (
    <Route
      {...rest}
      render={(matchProps: any) => (!isAuth ? <Component {...matchProps} /> : <Redirect to="/" />)}
    />
  );
});
