import { CustomModal } from "@components";
import { ClientInput, ORGANIZATION_QUERY } from "@graphql";
import { Button, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { appContext } from "@utils";
import csvtojson from 'csvtojson';
import { useFormik } from "formik";
import React, { useContext, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";

interface IProps {
  handleClose: () => void;
}

function LinearProgressWithLabel({ value, ..._rest }: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width:'20rem', pt:'1rem' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {..._rest} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


export function ImportClient({ handleClose }:IProps) {

  const { id } = useParams<any>();
  const [uploadedFile, setFile] = useState<File | null>(null)
  // const [uploadStatus, setUploadStatus] = useState<string>('')
  const [progress, setProgress] = useState<number>(0)
  
  const initialValues = useMemo<{clients: ClientInput[]}>(
    () => ({clients: []}),
    [],
    );
  
  const fileReader = useMemo(()=> new FileReader(), []);
  const {
    organizationStore: { addBulkClientToOrg },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues,
    onSubmit: async ({clients}) =>(await addBulkClientToOrg(clients, id)) &&
      await getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(id)] }) &&
      handleClose()
  });
    
  const handleChange = (file: File)=>{
    fileReader.readAsText(file);
    fileReader.onload = async (event) => {
      const output = event?.target?.result;
      if (output && typeof output === 'string') {
        const result = await csvtojson().fromString(output)
        formik.setFieldValue('clients', result);
      }
    };
    fileReader.addEventListener('progress', (event: any) => {
      // setUploadStatus('Uploading')
      if (event.loaded && event.total) {
        const percent = (event.loaded / event.total) * 100
        if (percent === 100) {
          // setUploadStatus('completed')
        }
        setProgress(Math.round(percent))
      }
    })
    setFile(file)
    // await csvtojson().fromFile(file.)
  }

  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Import Client
        </Typography>
        {uploadedFile === null ? (
          <FileUploader handleChange={handleChange} name="file" types={['CSV']} />
        ) : (
          <Box>
            <LinearProgressWithLabel value={progress} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                pt: '1rem',
              }}
            >
              <Button variant="contained" onClick={()=>formik.handleSubmit()} >Submit</Button>
            </Box>
          </Box>
        )}
        {/* <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            type="email"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Phone"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
          />

          <Select
            sx={sx.formItems}
            fullWidth
            name="role"
            onChange={formik.handleChange}
            value={formik.values.role}
          >
            <MenuItem value={ClientRole.Admin}>{ClientRole.Admin}</MenuItem>
            <MenuItem value={ClientRole.Basic}>{ClientRole.Basic}</MenuItem>
            <MenuItem value={ClientRole.Manager}>{ClientRole.Manager}</MenuItem>
          </Select>

          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            ADD
          </Button>
        </form> */}
      </>
    </CustomModal>
  );
}