import React, { useContext, useMemo } from 'react';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { ALL_ADVISORS_QUERY, UpdateAdvisorInput } from '@graphql';
import { CustomModal } from '@components';
import { appContext } from '@utils';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WorkIcon from '@mui/icons-material/Work';
import AccountCircle from '@mui/icons-material/AccountCircle';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
});

interface IProps {
  handleClose: () => void;
  advisor: any;
}

export function EditAdvisors({ handleClose, advisor }: IProps) {
  const sx = makeStyles();

  const initialValues = useMemo<UpdateAdvisorInput>(
    () => ({
      id: advisor.id,
      firstName: advisor.firstName,
      lastName: advisor.lastName,
      company: advisor.company,
      phone: advisor.phone,
      title: advisor.title,
    }),
    [advisor],
  );

  const {
    advisorStore: { updateAdvisor },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) =>
      (await updateAdvisor(values)) &&
      (await getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] })) &&
      handleClose(),
  });

  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Edit Advisor
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Title"
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Company"
            name="company"
            onChange={formik.handleChange}
            value={formik.values.company}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorkIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Phone"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            Save
          </Button>
        </form>
      </>
    </CustomModal>
  );
}
