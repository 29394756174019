/* eslint-disable consistent-return */
import { flow, makeAutoObservable } from 'mobx';
import {
  AdvisorInput,
  CREATE_ADVISOR_MUTATION,
  DELETE_ADVISOR_MUTATION,
  DeleteAdvisorInput,
  UPDATE_ADVISOR_DETAIL_MUTATION,
  UpdateAdvisorInput,
} from '@graphql';
import { graphqlApi } from '@utils';
import { toast } from 'react-toastify';
import { store } from '@store';
import { DEFAULT_HTTP_RESPONSE } from './defaults';

export class AdvisorsStore {
  createAdvisorResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  deleteAdvisorResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  updateAdvisorResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  constructor() {
    makeAutoObservable(this);
  }

  createAdvisor = flow(function* createAdvisor(this: AdvisorsStore, variables: AdvisorInput): any {
    try {
      this.createAdvisorResponse.status = 'pending';
      // // Continue with creating the advisor
      const userResponse = yield graphqlApi(CREATE_ADVISOR_MUTATION, variables);
      this.createAdvisorResponse.status = 'ok';
      if (userResponse?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      const userInfo = userResponse?.data?.data?.createAdvisor;
      if (userInfo) toast('Advisor created.', { type: 'success' });
      else toast('Account already exists.', { type: 'info' });
      return "userInfo";
    } catch (err) {
      toast('Something went wrong', { type: 'success' });
      this.createAdvisorResponse.status = 'badRequest';
      this.createAdvisorResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  deleteAdvisor = flow(function* deleteAdvisor(
    this: AdvisorsStore,
    variables: DeleteAdvisorInput,
  ): any {
    try {
      this.deleteAdvisorResponse.status = 'pending';
      const userResponse = yield graphqlApi(DELETE_ADVISOR_MUTATION, variables);
      this.deleteAdvisorResponse.status = 'ok';
      if (userResponse?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      const advisorDeleted = userResponse?.data?.data?.deleteAdvisor;
      if (advisorDeleted) toast('Advisor deleted.', { type: 'success' });
      return advisorDeleted;
    } catch (err) {
      toast('Something went wrong', { type: 'success' });
      this.createAdvisorResponse.status = 'badRequest';
      this.createAdvisorResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  updateAdvisor = flow(function* updateAdvisor(
    this: AdvisorsStore,
    variables: UpdateAdvisorInput,
  ): any {
    try {
      this.updateAdvisorResponse.status = 'pending';
      const userResponse = yield graphqlApi(UPDATE_ADVISOR_DETAIL_MUTATION, variables);
      this.updateAdvisorResponse.status = 'ok';
      if (userResponse?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      const userInfo = userResponse?.data?.data?.updateAdvisorDetail;
      if (userInfo) toast('Advisor updated.', { type: 'success' });
      else toast('Account already exist.', { type: 'info' });
      return !!userInfo;
    } catch (err) {
      toast('Something went wrong', { type: 'success' });
      this.updateAdvisorResponse.status = 'badRequest';
      this.updateAdvisorResponse.errMessage = 'Bad Request';
    }
  }).bind(this);
}
