/* eslint-disable */
import React, {useContext} from "react";
import { makeStyles } from "@mui/styles";
import { Switch,Redirect } from "react-router-dom";
import {appContext, paths, Routes} from "@utils";
import {NavBar} from "@components";
const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    flex: 1,
    overflow: "auto",
    padding:'2rem 2rem',
  }
}));

export const AuthLayout = () => {
  const classes = useStyles();
  const {userStore:{user:{adminRole}}} = useContext(appContext);
    return (
      <>
      <NavBar/>
      <main className={classes.layoutContainer}>
        <Switch>
          {Routes('auth',adminRole)}
          <Redirect to={paths.organizations}/>
        </Switch>
      </main>
      </>
    );
};
