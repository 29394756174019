import React, { useContext } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { appContext } from '@utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';

const makeStyles = () => ({
  pageContainer: {
    height: '100%',
  },
  formItems: {
    margin: '1rem 0',
  },
  formIcons: {
    cursor: 'pointer',
  },
  accordianPaper: { padding: '1rem', margin: '1rem 0', textAlign: 'end' },
});

interface IProps {
  name: string;
  children: React.ReactNode;
}

function TaxAccordian({ name, children }: IProps) {
  return (
    <Accordion sx={{ margin: '1rem 0' }} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ color: 'text.primary' }}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: 'center' }}>{children}</AccordionDetails>
    </Accordion>
  );
}

export const ExpensesSettings = observer(() => {
  const sx = makeStyles();
  const {
    userStore: { settings, getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);
  return (
    <Formik
      initialValues={settings}
      enableReinitialize
      onSubmit={async (values) =>
        (await updateSettings(values)) &&
        (await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]))
      }
    >
      {(formik) => (
        <form style={{ height: '100%' }} onSubmit={formik.handleSubmit}>
          <Grid sx={sx.pageContainer} container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <TaxAccordian name="Thresholds">
                <Paper sx={sx.accordianPaper} elevation={2}>
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Transportation"
                    name="expenses.thresholds.transportationTotal"
                    onChange={formik.handleChange}
                    value={formik.values.expenses?.thresholds?.transportationTotal || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Housing"
                    name="expenses.thresholds.housingTotal"
                    onChange={formik.handleChange}
                    value={formik.values.expenses?.thresholds?.housingTotal || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Housing Utilities"
                    name="expenses.thresholds.housingUtilities"
                    onChange={formik.handleChange}
                    value={formik.values.expenses?.thresholds?.housingUtilities || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Personal Care Clothing"
                    name="expenses.thresholds.personalCareClothing"
                    onChange={formik.handleChange}
                    value={formik.values.expenses?.thresholds?.personalCareClothing || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Personal Care Groceries"
                    name="expenses.thresholds.personalCareGroceries"
                    onChange={formik.handleChange}
                    value={formik.values.expenses?.thresholds?.personalCareGroceries || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Personal Care Medical"
                    name="expenses.thresholds.personalCareMedical"
                    onChange={formik.handleChange}
                    value={formik.values.expenses?.thresholds?.personalCareMedical || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Recreation"
                    name="expenses.thresholds.recreationTotal"
                    onChange={formik.handleChange}
                    value={formik.values.expenses?.thresholds?.recreationTotal || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
              </TaxAccordian>

              <Button fullWidth variant="contained" disabled={formik.isSubmitting} type="submit">
                SAVE
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
});
