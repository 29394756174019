import React, { useContext } from 'react';
import { Grid, useTheme, TextField, Button, Typography, InputAdornment } from '@mui/material';
import { Theme } from '@mui/system';
import Logo from '@assets/img/logo.png';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import { appContext } from '@utils';
import { useFormik } from 'formik';
import { UserType } from '@graphql';

const makeStyles = (theme: Theme) => ({
  pageContainer: {
    height: 'inherit',
    position: 'relative',
  },
  formContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: '2rem',
    textAlign: 'center',
    borderRadius: '10px',
  },
  logo: {
    maxWidth: '15rem',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  formItems: {
    margin: '1rem 0',
  },
});
export function ForgotPasswordPage() {
  const theme = useTheme();
  const sx = makeStyles(theme);
  const {
    userStore: { resetPasswordRequest },
  } = useContext(appContext);
  const formik = useFormik({
    initialValues: { email: '', type: UserType.SuperAdmin },
    onSubmit: async (values) => {
      await resetPasswordRequest(values);
    },
  });
  return (
    <Grid container justifyContent="center" alignItems="center" sx={sx.pageContainer}>
      <img src={Logo} alt="logo" style={sx.logo as any} />
      <Grid item sx={sx.formContainer} xs={12} sm={12} md={6} lg={4} xl={4}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Forgot Password
        </Typography>
        <LockIcon sx={{ color: 'text.primary', fontSize: '3rem' }} />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            type="email"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            sx={sx.formItems}
            disabled={formik.isSubmitting}
            fullWidth
            variant="contained"
            type="submit"
          >
            Forgot Password
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}
