import React, { useContext, useState } from 'react';
import {
  Grid,
  useTheme,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Theme } from '@mui/system';
import Logo from '@assets/img/logo.png';
import LockResetIcon from '@mui/icons-material/LockReset';
import { appContext } from '@utils';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import Lock from '@mui/icons-material/Lock';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import * as Yup from 'yup';

const makeStyles = (theme: Theme) => ({
  pageContainer: {
    height: 'inherit',
    position: 'relative',
  },
  formContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: '2rem',
    textAlign: 'center',
    borderRadius: '10px',
  },
  logo: {
    maxWidth: '15rem',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  formItems: {
    margin: '1rem 0',
  },
});
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required.')
    .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])/, 
    `Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character.`)
    .min(8, 'Password must be at least 8 characters long.'),
});
export function ResetPasswordPage() {
  const theme = useTheme();
  const sx = makeStyles(theme);
  const {
    userStore: { resetPassword },
  } = useContext(appContext);
  const [showPassword, setShow] = useState<boolean>(false);
  const { tokenId, userId } = useParams<any>();
  const formik = useFormik({
    initialValues: { password: '', tokenId, userId },
    validationSchema,
    onSubmit: async (values) => {
      await resetPassword(values);
    },
  });
  return (
    <Grid container justifyContent="center" alignItems="center" sx={sx.pageContainer}>
      <img src={Logo} alt="logo" style={sx.logo as any} />
      <Grid sx={sx.formContainer} item xs={12} sm={12} md={6} lg={4} xl={4}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Reset Password
        </Typography>
        <LockResetIcon sx={{ color: 'text.primary', fontSize: '3rem' }} />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            variant="outlined"
            type={(showPassword && 'text') || 'password'}
            required
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button sx={sx.formItems} fullWidth variant="contained" type="submit">
            Reset
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}
