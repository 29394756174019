export const USER_FRAGMENT = (query?: IResolvableFields, fragments?: Array<string>): string =>
  `fragment User on User {
 __typename
 ... on SuperAdmin{
    id
    email
    name
    adminRole:role
    ${query?.superAdmin?.join('') || ''}
}
 ... on Advisor{
    id
    firstName
    lastName
    company
    title
    advisorPhone:phone
    email
    ${query?.client?.join('') || ''}
}
... on Client{
    id
    firstName
    lastName
    phone
    email
    workEmail
    role
    invited
    ${query?.advisor?.join('') || ''}
}
}
${fragments?.join('') || ''}
`;

export const SESSION_QUERY = (userFragment: string): string => `query{
  currentSession{
    user{
      ...User  
    }
    auth{
    token
    }
  }
}
${userFragment}
`;

export const ORGANIZATION_QUERY = (id: string): string => `organization(id:"${id}"){
id
name
allClients{
firstName
lastName
email
workEmail
id
phone
role
invited
invitationStatus
onboardingStatus
advisor{
    id
    firstName
    lastName
    company
    title
    phone
    email
}
    }
}`;

export const ALL_ADVISORS_QUERY = `allAdvisors{
    id
    firstName
    lastName
    company
    title
    phone
    email
    bookingUrl
}`;

export const ALL_ADVISOR_REQUESTS_QUERY = `allAdvisorRequests{
    id
    client{
        id
        email
        firstName
        lastName
        org {
            name
        }
    }
}`;

export const ALL_ORGANIZATIONS_QUERY = `allOrganizations{
    id
    name
    admin{
        id
        name
        email
    }
    advisor{
        id
        firstName
        lastName
        company
        title
        phone
        email
    }
    status
    allClients{
        id
    }
}`;

export const ALL_ADMINS_QUERY = `query{
    allAdmins{
        id
        email
        role
        name
    }
}`;

export const SETTINGS_QUERY = `settings{
        ...settings   
}
`;
export const SETTINGS_FRAGMENT = `
    fragment settings on Settings {
    tax {
        cppIncomeThreshold
        cppCredit {
        ...taxCredit
        }
        eiCredit {
        ...taxCredit
        }
        personalCredit
        refundableTaxClaim
        federal {
        ...taxSlab
        }
        ab {
        ...taxSlab
        }
        bc {
        ...taxSlab
        }
        mb {
        ...taxSlab
        }
        nb {
        ...taxSlab
        }
        nl {
        ...taxSlab
        }
        ns {
        ...taxSlab
        }
        nt {
        ...taxSlab
        }
        nu {
        ...taxSlab
        }
        on {
        ...taxSlab
        }
        pe {
        ...taxSlab
        }
        qc {
        ...taxSlab
        }
        sk {
        ...taxSlab
        }
        yt {
        ...taxSlab
        }
    }
    emergencySavings {
        interestRate
    }
    insurance {
        life {
            ...settingsInsuranceLife
        }
    disability {
        incomeCoveragePercent
        }
    }
    
    expenses{
        thresholds {
        ...settingsExpensesThresholds
        }
    }
    
    debt {
        refinancingMortgage {
        ...settingsDebtInterest
        }
        refinancingByAllDebts {
        ...settingsDebtInterest
        }
        refinancingWithoutMortgage {
        ...settingsDebtInterest
        }
        mortgageQualification {
        ...settingsDebtMortageQualification
        }
    }
    
    retirement {
    appreciationRate
    inflationRate
    incomeRequiredAtRetirement
    definedContributionMaxLimit
    groupRrspContributionMaxLimit
    rrspContributionMaxLimit
    tfsaContributionMaxLimit
    maxCppPayout
    maxOasPayout
    oasStartingAge
    }
    
}


fragment taxCredit on TaxCredit {
    threshold
    contributionRate
}

fragment taxSlab on TaxSlab {
    limit
    rate
}

fragment settingsInsuranceLife on SettingsInsuranceLife {
    inflationRate
    incomePercentToReplace
    canadaPensionPlanPayout
    funeralExpenses
    survivorBenefitsUnder65
    survivorBenefitsAbove65
    childrensBenefits
}

fragment settingsExpensesThresholds on SettingsExpensesThresholds {
    transportationTotal
    recreationTotal
    housingTotal
    housingUtilities
    personalCareClothing
    personalCareMedical
    personalCareGroceries
}

fragment settingsDebtInterest on SettingsDebtInterest {
    interestRate
    totalYearsToPayDebt
}

fragment settingsDebtMortageQualification on SettingsDebtMortgageQualification {
    downPayment
    grossDebtServiceRatio
    interestRate
    propertyInsurance
    totalDebtServiceRatio
    totalYearsToPayDebt
}
`;

export const ALL_PENDING_USERS_BY_ORG_REQUESTS_QUERY = `
    query($orgId: ID!){
        allPendingUsersByOrg(orgId: $orgId){
            id
            email
            firstName
            lastName
            org {
                name
            }
        }
    }
`;