/* eslint-disable react/react-in-jsx-scope */
import { ALL_ORGANIZATIONS_QUERY, ClientOrganization, SuperAdminRole } from '@graphql';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileDownload as FileDownloadIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { appContext, paths } from '@utils';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AssignManager, CreateOrganization } from '..';
import { DeleteOrganization } from '../DeleteOrganization';
import { EditOrganization } from '../EditOrganization';
import { ExportUsers } from '../ExportUsers';

function CustomToolbar() {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <Button variant="contained" onClick={handleClose}>
        Create Organization
      </Button>
      {open && <CreateOrganization handleClose={handleClose} />}
    </GridToolbarContainer>
  );
}
export const AllOrganizationsTable = observer(() => {
  const [openManagerModal, setOpenManagerModal] = useState<boolean>(false);
  const [org, setOrg] = useState<ClientOrganization | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openExportUsersModal, setOpenExportUsersModal] = useState<boolean>(false);

  const {
    userStore: { allOrganizations, getCurrentSession, sessionResponse, user },
    historyStore: { history },
  } = useContext(appContext);

  useEffect(() => {
    getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY] });
  }, [getCurrentSession]);

  const handleManagerModal = useCallback(() => {
    setOpenManagerModal(!openManagerModal);
  }, [openManagerModal]);

  const handleDeleteModal = useCallback(() => {
    setOpenDeleteModal(!openDeleteModal);
  }, [openDeleteModal]);

  const handleEditModal = useCallback(() => {
    setOpenEditModal(!openEditModal);
  }, [openEditModal]);

  const handleExportUsersModal = useCallback(() => {
    setOpenExportUsersModal(!openExportUsersModal);
  }, [openExportUsersModal]);

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 1 },

      { field: 'name', headerName: 'Name', disableColumnMenu: true, flex: 1 },

      {
        field: 'admin',
        headerName: 'Account Manager',
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params: any) => params.row.admin.name,
      },
      {
        field: 'advisor',
        headerName: 'Advisor',
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params: any) =>
          `${params.row.advisor?.firstName} ${params.row.advisor?.lastName}`,
      },
      {
        field: 'status',
        headerName: 'Status',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'numberOfUsers',
        headerName: 'Clients',
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params: any) => params.row.allClients.length,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Organization"
            onClick={() => {
              setOrg(params.row);
              handleEditModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<SupervisorAccountIcon />}
            label="Change Manager"
            onClick={() => {
              setOrg(params.row);
              handleManagerModal();
            }}
            showInMenu
            sx={{ display: user.adminRole === SuperAdminRole.Admin ? '' : 'none' }}
          />,
          <GridActionsCellItem
            icon={<FileDownloadIcon />}
            label="Export Users"
            onClick={() => {
              setOrg(params.row);
              handleExportUsersModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Organization"
            onClick={() => {
              setOrg(params.row);
              handleDeleteModal();
            }}
            showInMenu
          />,
        ],
      },
    ],
    [
      user.adminRole,
      handleEditModal,
      handleManagerModal,
      handleDeleteModal,
      handleExportUsersModal,
    ],
  );

  return (
    <div style={{ height: 'inherit', width: '100%' }}>
      <DataGrid
        rows={allOrganizations.map((organization) => ({
          ...organization,
          id: organization.id,
          admin: organization.admin === null ? { name: 'No Manager' } : organization.admin,
          advisor:
            organization.advisor?.firstName === undefined
              ? { firstName: 'No', lastName: 'Advisor' }
              : organization.advisor,
        }))}
        sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' } }}
        columns={columns}
        isRowSelectable={() => false}
        loading={sessionResponse.status === 'pending'}
        onRowClick={(params: GridRowParams) => history.push(`${paths.organizations}/${params.id}`)}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      {openManagerModal && <AssignManager handleClose={handleManagerModal} org={org as any} />}
      {openDeleteModal && <DeleteOrganization handleClose={handleDeleteModal} org={org as any} />}
      {openEditModal && (
        <EditOrganization
          handleClose={handleEditModal}
          org={org as any}
          advisors={user.allAdvisors || []}
        />
      )}
      {openExportUsersModal && (
        <ExportUsers handleClose={handleExportUsersModal} org={org as any} />
      )}
    </div>
  );
});
