import React, { useContext } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import NumbersIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { appContext } from '@utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';

const makeStyles = () => ({
  pageContainer: {
    height: '100%',
  },
  formItems: {
    margin: '1rem 0',
  },
  formIcons: {
    cursor: 'pointer',
  },
  accordianPaper: { padding: '1rem', margin: '1rem 0', textAlign: 'end' },
});

interface IProps {
  name: string;
  children: React.ReactNode;
}

function TaxAccordian({ name, children }: IProps) {
  return (
    <Accordion sx={{ margin: '1rem 0' }} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ color: 'text.primary' }}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: 'center' }}>{children}</AccordionDetails>
    </Accordion>
  );
}

export const InsuranceSettings = observer(() => {
  const sx = makeStyles();
  const {
    userStore: { settings, getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);
  return (
    <Formik
      initialValues={settings}
      enableReinitialize
      onSubmit={async (values) =>
        (await updateSettings(values)) &&
        (await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]))
      }
    >
      {(formik) => (
        <form style={{ height: '100%' }} onSubmit={formik.handleSubmit}>
          <Grid sx={sx.pageContainer} container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <TaxAccordian name="Disability">
                <Paper sx={sx.accordianPaper} elevation={2}>
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Income Coverage"
                    name="insurance.disability.incomeCoveragePercent"
                    onChange={formik.handleChange}
                    value={formik.values.insurance?.disability?.incomeCoveragePercent || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
              </TaxAccordian>
              <TaxAccordian name="Life">
                <Paper sx={sx.accordianPaper} elevation={2}>
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Children Benefits"
                    name="insurance.life.childrensBenefits"
                    onChange={formik.handleChange}
                    value={formik.values.insurance?.life?.childrensBenefits || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="SB Under 65"
                    name="insurance.life.survivorBenefitsUnder65"
                    onChange={formik.handleChange}
                    value={formik.values.insurance?.life?.survivorBenefitsUnder65 || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="SB Above 65"
                    name="insurance.life.survivorBenefitsAbove65"
                    onChange={formik.handleChange}
                    value={formik.values.insurance?.life?.survivorBenefitsAbove65 || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Income Percent To Replace"
                    name="insurance.life.incomePercentToReplace"
                    onChange={formik.handleChange}
                    value={formik.values.insurance?.life?.incomePercentToReplace || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Inflation Rate"
                    name="insurance.life.inflationRate"
                    onChange={formik.handleChange}
                    value={formik.values.insurance?.life?.inflationRate || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="Funeral Expenses"
                    name="insurance.life.funeralExpenses"
                    onChange={formik.handleChange}
                    value={formik.values.insurance?.life?.funeralExpenses || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <NumbersIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={sx.formItems}
                    fullWidth
                    label="CPP Payout"
                    name="insurance.life.canadaPensionPlanPayout"
                    onChange={formik.handleChange}
                    value={formik.values.insurance?.life?.canadaPensionPlanPayout || ''}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <NumbersIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
              </TaxAccordian>

              <Button fullWidth variant="contained" disabled={formik.isSubmitting} type="submit">
                SAVE
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
});
