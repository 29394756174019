import React, { useMemo, useContext } from 'react';
import { CustomModal } from '@components';
import { ALL_ORGANIZATIONS_QUERY } from '@graphql';
import { Typography, Button } from '@mui/material';
import { appContext } from '@utils';
import { useFormik } from 'formik';

const makeStyles = () => ({
  formItems: {
    margin: '0.5rem 0',
    display: 'flex',
  },
});

interface IProps {
  handleClose: () => void;
  org: any;
}
export function DeleteOrganization({ handleClose, org }: IProps) {
  const sx = makeStyles();
  const orgId = useMemo(() => org.id, [org]);

  const {
    organizationStore: { deleteOrganization },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: { orgId },
    onSubmit: async () =>
      (await deleteOrganization({ id: orgId })) &&
      (await getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY] })) &&
      handleClose(),
  });
  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Delete Organization
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <div>
            Are you sure you want to delete the organization and all its employees&apos; accounts?
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '10px',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              sx={sx.formItems}
              fullWidth
              variant="contained"
              disabled={formik.isSubmitting}
              type="submit"
            >
              Yes
            </Button>
            <Button
              sx={sx.formItems}
              fullWidth
              variant="contained"
              disabled={formik.isSubmitting}
              type="button"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      </>
    </CustomModal>
  );
}
