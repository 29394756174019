import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { ClientOrganization, ORGANIZATION_QUERY } from '@graphql';
import { Download as DownloadIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import { CustomModal } from '@components';
import { appContext } from '@utils';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
});

interface IProps {
  handleClose: () => void;
  org: ClientOrganization;
}

export function ExportUsers({ handleClose, org }: IProps) {
  const sx = makeStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pendingUsers, setUsers] = useState<any>([]);
  const [text, setText] = useState('Loading...');

  const {
    userStore: { getCurrentSession, allClients, sessionResponse },
  } = useContext(appContext);

  const exportToXLSX = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `Users - ${org.name.substring(0, 15)}`,
    );

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    saveAs(blob, `users-from-${org.name}.xlsx`);
  };

  useEffect(() => {
    getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(org.id)] });
  }, [org.id, getCurrentSession]);

  useEffect(() => {
    if (sessionResponse.status === 'ok') {
      if (allClients.length === 0) {
        setError(true);
        setText('No users to export');
        setLoading(false);
        return;
      }
      setText(`Download users from ${org.name}`);
      setUsers(allClients);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClients, sessionResponse]);

  const handleDownload = () => {
    if (error) {
      handleClose();
      return;
    }

    exportToXLSX(
      pendingUsers.map((user: any) => ({
        'First Name': user.firstName,
        'Last Name': user.lastName,
        Email: user.email,
        Phone: user.phone,
        Role: user.role,
        Status: user.invitationStatus,
        Advisor: user?.advisor ? `${user.advisor.firstName} ${user.advisor.lastName}` : ``,
      })),
    );
    handleClose();
  };

  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          {text}
        </Typography>
        <Button
          sx={sx.formItems}
          fullWidth
          variant="contained"
          disabled={loading}
          startIcon={!error && <DownloadIcon />}
          type="submit"
          onClick={handleDownload}
        >
          {error ? 'close' : 'Download'}
        </Button>
      </>
    </CustomModal>
  );
}
