import { CustomModal } from '@components';
import { ClientRole, ORGANIZATION_QUERY, UpdateClientInput } from '@graphql';
import { AccountCircle, Phone } from '@mui/icons-material';
import { Typography, TextField, InputAdornment, Select, MenuItem, Button } from '@mui/material';
import { appContext } from '@utils';
import { useFormik } from 'formik';
import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

interface IProps {
  client: any;
  handleClose: () => void;
}

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
});

export function EditClient({ client, handleClose }: IProps) {
  const sx = makeStyles();
  const initialValues = useMemo<UpdateClientInput>(
    () => ({
      id: client.id,
      role: client.role,
      phone: client.phone,
      lastName: client.lastName,
      firstName: client.firstName,
      email: client.email === client.workEmail ? '' : client.email,
      workEmail: client.workEmail,
    }),
    [client],
  );

  const { id } = useParams<any>();

  const {
    organizationStore: { updateClientDetail },
    userStore: { getCurrentSession },
  } = useContext(appContext);

  const formik = useFormik<UpdateClientInput>({
    initialValues,
    onSubmit: async (values) =>
      (await updateClientDetail(values)) &&
      (await getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(id)] })) &&
      handleClose(),
  });
  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Edit Client
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Personal Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          {client.role !== ClientRole.Spouse && (
            <TextField
              sx={sx.formItems}
              fullWidth
              label="Work Email"
              name="workEmail"
              onChange={formik.handleChange}
              value={formik.values.workEmail}
              variant="outlined"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          )}

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Phone"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
          />

          <Select
            sx={sx.formItems}
            fullWidth
            name="role"
            onChange={formik.handleChange}
            value={formik.values.role}
          >
            <MenuItem value={ClientRole.Admin}>{ClientRole.Admin}</MenuItem>
            <MenuItem value={ClientRole.Basic}>{ClientRole.Basic}</MenuItem>
            <MenuItem value={ClientRole.Manager}>{ClientRole.Manager}</MenuItem>
          </Select>

          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            ADD
          </Button>
        </form>
      </>
    </CustomModal>
  );
}
