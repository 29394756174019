import React, { useContext, useState } from 'react';
import {
  Grid,
  useTheme,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Theme } from '@mui/system';
import Logo from '@assets/img/logo.png';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EmailIcon from '@mui/icons-material/Email';
import Lock from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { appContext, paths } from '@utils';
import { UserType } from '@graphql';

const makeStyles = (theme: Theme) => ({
  pageContainer: {
    height: 'inherit',
    position: 'relative',
  },
  formContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: '2rem',
    textAlign: 'center',
    borderRadius: '10px',
  },
  logo: {
    maxWidth: '15rem',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  formItems: {
    margin: '1rem 0',
  },
});
export function LoginPage() {
  const theme = useTheme();
  const sx = makeStyles(theme);
  const [showPassword, setShow] = useState<boolean>(false);
  const {
    userStore: { logIn },
  } = useContext(appContext);
  const formik = useFormik({
    initialValues: { email: '', password: '', type: UserType.SuperAdmin },
    onSubmit: async (values) => {
      // await signIn(values.email, values.password)
      await logIn(values);
    },
  });
  return (
    <Grid container justifyContent="center" alignItems="center" sx={sx.pageContainer}>
      <img src={Logo} alt="logo" style={sx.logo as any} />
      <Grid sx={sx.formContainer} item xs={12} sm={12} md={6} lg={4} xl={4}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Sign In
        </Typography>
        <AdminPanelSettingsIcon sx={{ color: 'text.primary', fontSize: '3rem' }} />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            type="email"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            variant="outlined"
            type={(showPassword && 'text') || 'password'}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography sx={{ color: 'primary.main', textAlign: 'end' }}>
            <Link style={{ color: 'inherit' }} to={paths.forgot}>
              Forgot Password?
            </Link>
          </Typography>
          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            LOGIN
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}
