import React, { useContext, useMemo } from 'react';
import { Button, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Phone from '@mui/icons-material/Phone';
import { useFormik } from 'formik';
import { ClientInput, ClientRole, ORGANIZATION_QUERY } from '@graphql';
import { CustomModal } from '@components';
import EmailIcon from '@mui/icons-material/Email';
import { appContext } from '@utils';
import { useParams } from 'react-router-dom';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
});
interface IProps {
  handleClose: () => void;
}
export function AddClient({ handleClose }: IProps) {
  const sx = makeStyles();
  const { id } = useParams<any>();
  const initialValues = useMemo<ClientInput>(
    () => ({ email: '', role: ClientRole.Basic, phone: '', lastName: '', firstName: '', org: id }),
    [id],
  );
  const {
    organizationStore: { addClientToOrg },
    userStore: { getCurrentSession },
  } = useContext(appContext);
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) =>
      (await addClientToOrg(values)) &&
      await getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(id)] }) &&
      handleClose(),
  });
  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Add Client
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            type="email"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Phone"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
          />

          <Select
            sx={sx.formItems}
            fullWidth
            name="role"
            onChange={formik.handleChange}
            value={formik.values.role}
          >
            <MenuItem value={ClientRole.Admin}>{ClientRole.Admin}</MenuItem>
            <MenuItem value={ClientRole.Basic}>{ClientRole.Basic}</MenuItem>
            <MenuItem value={ClientRole.Manager}>{ClientRole.Manager}</MenuItem>
          </Select>

          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            ADD
          </Button>
        </form>
      </>
    </CustomModal>
  );
}
