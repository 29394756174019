import { SETTINGS_FRAGMENT, USER_FRAGMENT } from './queries';

export const LOGIN_MUTATION = `mutation ($type: UserType!,$email:String!,$token:String!){
  login(type:$type,email:$email,token:$token){
    user{
      ...User  
    }
    auth{
    token
    }
  }
}
${USER_FRAGMENT()}
`;

export const RESET_PASSWORD_REQUEST_MUTATION = `mutation ($type: UserType!,$email:String!) {
requestResetPassword(
type: $type
email:$email
)
}`;
export const RESET_PASSWORD_MUTATION = `
  mutation ($tokenId: ID!, $userId: ID!, $password: String!, $email: String) {
    resetPasswordAndEmail(tokenId: $tokenId, userId: $userId, newPassword: $password, email: $email) {
      user {
        ...User
      }
      auth {
        token
      }
    }
  }
  ${USER_FRAGMENT()}
`;

export const CREATE_ADVISOR_MUTATION = `mutation ($email: String!
$firstName: String!
$lastName: String!
$password:String!
$company:String!
$title:String
$phone:String) {
    createAdvisor(email: $email
    firstName: $firstName
    lastName: $lastName
    password: $password
    company: $company
    title: $title
    phone: $phone) {
        firstName
        lastName
        company
        title
        phone
        email
    }
}`;

export const DELETE_ADVISOR_MUTATION = `mutation ($id: ID!) {
    deleteAdvisor(id: $id)
}`;

export const UPDATE_ADVISOR_DETAIL_MUTATION = `mutation ($id: ID!
$firstName: String
$lastName: String
$company: String
$title: String
$phone: String) {
    updateAdvisorDetail(id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    title: $title
    phone: $phone) {
        id
        firstName
        lastName
        company
        title
        phone
    }
}`;

export const CREATE_MANAGER_MUTATION = `mutation ($email: String!
$name: String!) {
    createManager(email: $email
    name: $name
    ) {
        id
        name
        email
        role
    }
}`;

export const ASSIGN_MANAGER_MUTATION = `mutation ($managerId: ID!
$orgId: ID!) {
    assignManager(managerId: $managerId
    orgId: $orgId
    ) {
        id
        name
    }
}`;

export const CREATE_CLIENT_ORG_MUTATION = `mutation(
$email: String!
$firstName: String!
$lastName: String!
$name: String!
$message: String
$managerId:ID
$advisorId:ID
) {
    createClientOrganization(
    email: $email
    firstName: $firstName
    lastName: $lastName
    name: $name
    message: $message
    managerId:$managerId
    advisorId:$advisorId
) {
        id
        name
    }
}`;

export const UPDATE_SETTINGS_MUTATION = `mutation(
$input: SettingsInput!
) {
    updateSettings(
        input:$input
) {
        ...settings
    }
}
${SETTINGS_FRAGMENT}
`;

export const ADD_CLIENT_TO_ORG_MUTATION = `
mutation(
$org: ID!
$role:ClientRole
$phone:String
$email: String!
$firstName: String!
$lastName: String!
) {
    addClientToOrg(
    org: $org
    role:$role
    phone:$phone
    email: $email
    firstName: $firstName
    lastName: $lastName
) {
        id
        firstName
        lastName
        phone
        role
        email
    }
}
`;

export const DELETE_CLIENTS_FROM_ORG_MUTATION = `
  mutation DeleteClientsFromOrganization ($orgId: ID!, $clients: [ID!]) {
    deleteClientsFromOrg(orgId: $orgId, clients: $clients)
  }
`;

export const INVITE_CLIENT = `
  mutation ($input: [ID!]!) {
    sendInviteEmails(clientIds:$input)
  }
`;

export const ADD_BULK_CLIENT_TO_ORG_MUTATION = `
mutation(
$input: [ClientImportInput!]!,
$org_id: ID
) {
    bulkImportClients(
    input: $input,
    org_id: $org_id
) {
        id
        firstName
        lastName
        phone
        role
        email
    }
}
`;

export const ASSIGN_ADVISOR_TO_CLIENT_MUTATION = `
mutation(
$clientId:ID!
$advisorId:ID!
) {
    assignAdvisorToClient(
        clientId: $clientId
        advisorId: $advisorId
    ) {
            id
            email
            firstName
            lastName
    }
}`;

export const DELETE_ORGANIZATION_MUTATION = `
mutation ($id: ID!)  {
    deleteOrganization(id: $id)
  }
`;

export const UPDATE_CLIENT_ORGANIZATION_MUTATION = `
mutation(
    $id: ID!
    $name: String
    $status: String
    $advisorId: ID
) {
    updateClientOrganization(
        id: $id,
        name: $name
        status: $status
        advisorId: $advisorId
        ) {
            id
            name
            status
            advisor {
                id
            }
        }
}`;

export const DELETE_ADMIN_MUTATION = `
mutation ($id: ID!)  {
    deleteSuperAdmin(id: $id)
    }
`;

export const UPDATE_ADMIN_MUTATION = `
mutation (
    $id: ID!
    $name: String
    $role: String
)  {
    updateAdmin(id: $id, 
        name: $name,
        role: $role
        ) {
        id
        name
        email
        role
    }
}
`;

export const UPDATE_CLIENT_DETAIL_MUTATION = `
mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $phone: String
    $role: ClientRole
    $email: String
    $workEmail: String
)  {
    updateClientDetail(id: $id, 
        firstName: $firstName,
        lastName: $lastName,
        phone: $phone,
        role: $role
        email: $email
        workEmail: $workEmail
        ) {
        id
        firstName
        lastName
        phone
        role
        email
        workEmail
    }
}
`;

export const UPDATE_CLIENT_EMAIL_MUTATION = `
mutation (
    $id: ID!
    $email: String!
)  {
    updateClientEmail(id: $id, 
        email: $email
        ) {
        updated
        message
    }
}
`;
