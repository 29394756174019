import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { appContext } from '@utils';
import { ALL_ADVISORS_QUERY, Advisor, SuperAdminRole } from '@graphql';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CreateAdvisors } from '../CreateAdvisors';
import { DeleteAdvisors } from '../DeleteAdvisors';
import { EditAdvisors } from '../EditAdvisors';

function CustomToolbar() {
  const [open, setOpen] = useState<boolean>(false);

  const {
    userStore: { user },
  } = useContext(appContext);
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      {user.adminRole === SuperAdminRole.Admin && (
        <Button variant="contained" onClick={handleClose}>
          Create Advisor
        </Button>
      )}
      {open && <CreateAdvisors handleClose={handleClose} />}
    </GridToolbarContainer>
  );
}

export const AllAdvisorsTable = observer(() => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [advisor, setAdvisor] = useState<Advisor | null>(null);
  const {
    userStore: { allAdvisors, getCurrentSession, sessionResponse },
  } = useContext(appContext);
  useEffect(() => {
    getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] });
  }, [getCurrentSession]);

  const handleEditModal = useCallback(() => {
    setOpenEdit(!openEdit);
  }, [openEdit]);

  const handleDeleteModal = useCallback(() => {
    setOpenDelete(!openDelete);
  }, [openDelete]);

  const isAdmin = useContext(appContext).userStore.user.adminRole === SuperAdminRole.Admin;

  const columnsWithActions = React.useMemo<GridColumns>(
    () => [
      { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 1 },
      { field: 'email', headerName: 'Email', disableColumnMenu: true, flex: 1 },
      { field: 'firstName', headerName: 'First Name', disableColumnMenu: true, flex: 1 },
      { field: 'lastName', headerName: 'Last Name', disableColumnMenu: true, flex: 1 },
      { field: 'phone', headerName: 'Phone', disableColumnMenu: true, flex: 1 },
      { field: 'company', headerName: 'Company', disableColumnMenu: true, flex: 1 },
      { field: 'title', headerName: 'Title', disableColumnMenu: true, flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Advisor"
            onClick={() => {
              setAdvisor(params.row);
              handleEditModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Advisor"
            onClick={() => {
              setAdvisor(params.row);
              handleDeleteModal();
            }}
            showInMenu
          />,
        ],
      },
    ],
    [handleDeleteModal, handleEditModal],
  );

  const columns = isAdmin
  ? columnsWithActions
  : columnsWithActions.filter((column) => column.field !== 'actions');
  return (
    <div style={{ height: 'inherit', width: '100%' }}>
      <DataGrid
        rows={allAdvisors}
        columns={columns}
        isRowSelectable={() => false}
        loading={sessionResponse.status === 'pending'}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      {openEdit && <EditAdvisors handleClose={handleEditModal} advisor={advisor} />}
      {openDelete && <DeleteAdvisors handleClose={handleDeleteModal} advisor={advisor} />}
    </div>
  );
});
