import React from 'react';
import { getRoutes } from '@utils';
import { Route } from 'react-router-dom';
import { UnAuthRoute, AuthRoute } from '@components';
import {SuperAdminRole} from "@graphql";

export const Routes = (routeType: RouteType,userRole?:SuperAdminRole): Array<JSX.Element> =>
  getRoutes(routeType,userRole).map((item) => {
    if (routeType === 'auth') {
      return (
        <AuthRoute
          key={routeType + item.path}
          exact={item.exact}
          path={item.path}
          Component={item.component}
        />
      );
    }
    if (routeType === 'unAuth') {
      return (
        <UnAuthRoute
          key={routeType + item.path}
          exact={item.exact}
          path={item.path}
          Component={item.component}
        />
      );
    }
    return (
      <Route
        key={routeType + item.path}
        exact={item.exact}
        path={item.path}
        component={item.component}
      />
    );
  });
