import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { dark } from '@styles';
import { ThemeProvider, createTheme } from '@mui/material';
import { appContext } from '@utils';
import { store } from '@store';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import App from './App';

function Application() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(() => createTheme(dark), []);
  return (
    <appContext.Provider value={store}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          autoClose={2000}
          toastStyle={{
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        />
        <App />
      </ThemeProvider>
    </appContext.Provider>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
