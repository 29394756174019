import React, { useContext, useMemo, useState } from 'react';
import { Button, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useFormik } from 'formik';
import { AdvisorInput, ALL_ADVISORS_QUERY } from '@graphql';
import { CustomModal } from '@components';
import Lock from '@mui/icons-material/Lock';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import { appContext } from '@utils';
import * as Yup from 'yup';

const makeStyles = () => ({
  formItems: {
    margin: '1rem 0',
  },
});
interface IProps {
  handleClose: () => void;
}
export function CreateAdvisors({ handleClose }: IProps) {
  const sx = makeStyles();
  const [showPassword, setShow] = useState<boolean>(false);
  const {
    advisorStore: { createAdvisor },
    userStore: { getCurrentSession },
  } = useContext(appContext);
  const initialValues = useMemo<AdvisorInput>(
    () => ({ email: '', lastName: '', password: '', firstName: '', company: '', phone: '' }),
    [],
  );
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please provide a valid email address.').required('Email is required.'),
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    password: Yup.string()
      .required('Password is required.')
      .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])/, 
    `Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character.`)
      .min(8, 'Password must be at least 8 characters long.'),
    company: Yup.string().required('Company is required.'),
    phone: Yup.number().required('Phone is required.'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) =>
      (await createAdvisor({ ...values, phone: values.phone.toString() })) &&
      (await getCurrentSession({ superAdmin: [ALL_ADVISORS_QUERY] })) &&
      handleClose(),
  });
  return (
    <CustomModal handleClose={handleClose}>
      <>
        <Typography sx={{ color: 'text.primary' }} variant="h6" component="h2">
          Create Advisor
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="outlined"
            type="email"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            variant="outlined"
            type={(showPassword && 'text') || 'password'}
            required
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Comapny"
            name="company"
            onChange={formik.handleChange}
            value={formik.values.company}
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorkIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="Phone"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            type="number"
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            sx={sx.formItems}
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            Save
          </Button>
        </form>
      </>
    </CustomModal>
  );
}
